import React from "react";
import { Link } from "react-router-dom";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { URLS } from "_configs/URLS";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";

export function PropertyMapLinks() {
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();
    if (!propertyStore.property?.address?.province && !propertyStore.property?.address?.city) return null;
    return (
        <Container>
            <div className={"flex_column_center"}>
                <div className={"flex_row_center"}>
                    <Link
                        target={"_blank"}
                        rel="noopener noreferrer"
                        to={reformatStringForUrls(
                            URLS[propertyStore.property.purpose === PROPERTY_PURPOSE.BUY ? "buy" : "rent"](
                                undefined,
                                propertyStore.property.address.province,
                                propertyStore.property.address.city,
                                t("property.typesUrl." + propertyStore.property.type),
                            ),
                        )}
                    >
                        <UiButton variant={"outlined"} color={"primary"}>
                            {t(
                                `propertyPage.linksLabels.${propertyStore.property.purpose.toLowerCase()}.${propertyStore.property.type.toLowerCase()}`,
                                {
                                    city: propertyStore.property.address.city,
                                },
                            )}
                        </UiButton>
                    </Link>
                    {propertyStore.property.address?.neighbourhood && (
                        <Link
                            className={"ml_20"}
                            target={"_blank"}
                            rel="noopener noreferrer"
                            to={reformatStringForUrls(
                                URLS[propertyStore.property.purpose === PROPERTY_PURPOSE.BUY ? "buy" : "rent"](
                                    undefined,
                                    propertyStore.property.address.province,
                                    propertyStore.property.address.city,
                                    propertyStore.property.address.neighbourhood,
                                    t("property.typesUrl." + propertyStore.property.type),
                                ),
                            )}
                        >
                            <UiButton variant={"outlined"} color={"primary"}>
                                {t(
                                    `propertyPage.linksLabels.${propertyStore.property.purpose.toLowerCase()}.${propertyStore.property.type.toLowerCase()}`,
                                    {
                                        city: propertyStore.property.address.neighbourhood,
                                    },
                                )}
                            </UiButton>
                        </Link>
                    )}
                </div>
            </div>
        </Container>
    );
}
