import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./_css/virtualAssistantFinalStepForm.module.css";
import clsx from "clsx";
import { VirtualAssistantPropertiesLoader } from "virtualAssistant/VirtualAssistantPropertiesLoader";
import { TFilter } from "admin/_common/filters/TFilter";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { VirtualAssistantNoResults } from "virtualAssistant/VirtualAssistantNoResults";
import { observer } from "mobx-react";

type Props = {
    filters: TFilter[];
    goToStepOne: () => void;
};
export const VirtualAssistantFinalStepForm = observer(({ filters, goToStepOne }: Props) => {
    const { t } = useTranslation();

    const listStore = propertiesStore.getListStore("virtual-assistant-summary", undefined, filters);

    useEffect(() => {
        listStore.reload();
    }, []);

    return (
        <div className={"flex_column"}>
            <div className={"mt_50"}>
                {listStore.items.length === 0 ? (
                    <VirtualAssistantNoResults goToStepOne={goToStepOne} />
                ) : (
                    <VirtualAssistantPropertiesLoader filters={filters} />
                )}
            </div>
            <div className={clsx("flex_column", styles.agreementContainer)}>
                <div>{t("virtualAssistant.finalStepForm.agreementTitle")}</div>
                <div
                    className={styles.answer}
                    dangerouslySetInnerHTML={{ __html: t("virtualAssistant.finalStepForm.agreementText") }}
                />
            </div>
        </div>
    );
});
