import React from "react";
import { Meta } from "_common/_utils/Meta";
import { useTranslation } from "react-i18next";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import i18next from "i18next";
import { URLS } from "_configs/URLS";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { getI18nExpByLang } from "_common/_utils/pageUtils";

type Props = {
    purpose?: PROPERTY_PURPOSE;
};

export function PropertiesMeta(props: Props) {
    const { t } = useTranslation();
    const { isCityPage, isProvincePage, propertyTypeLocalized, propertyTypeKey, page } = useSearchPageParams();
    const propertiesStore = usePropertiesStore();
    const pageParams = page && page > 1 ? `/p_${page}` : "";
    const title = isProvincePage
        ? t("propertiesPage.meta.title", {
              region: propertiesStore.addressParams.region,
              city: propertiesStore.addressParams.neighbourhood ?? propertiesStore.addressParams.city,
          })
        : undefined;
    const description = isProvincePage
        ? t("propertiesPage.meta.description", {
              region: propertiesStore.addressParams.region,
              city: propertiesStore.addressParams.neighbourhood ?? propertiesStore.addressParams.city,
          }) +
          t(`propertiesPage.meta.purpose.${props.purpose}`, {
              city: propertiesStore.addressParams.neighbourhood ?? propertiesStore.addressParams.city,
          })
        : undefined;
    const alternateUrls = [];
    for (const lang of Object.keys(sharedConfig.languages)) {
        let route = "";
        if (isCityPage) {
            route =
                sharedConfig.appUrl +
                "/" +
                lang +
                "/" +
                getI18nExpByLang(lang, props.purpose === PROPERTY_PURPOSE.BUY ? "routes.buy" : "routes.rent") +
                "/" +
                reformatStringForUrls(propertiesStore.addressParams.region) +
                "/" +
                reformatStringForUrls(propertiesStore.addressParams.city) +
                (propertiesStore.addressParams?.neighbourhood
                    ? `/${reformatStringForUrls(propertiesStore.addressParams?.neighbourhood)}`
                    : "") +
                (propertyTypeKey
                    ? `/${getI18nExpByLang(lang, "property.typesUrl." + propertyTypeKey).toLowerCase()}`
                    : "") +
                pageParams;
        } else if (isProvincePage) {
            route =
                sharedConfig.appUrl +
                "/" +
                lang +
                "/" +
                getI18nExpByLang(lang, props.purpose === PROPERTY_PURPOSE.BUY ? "routes.buy" : "routes.rent") +
                "/" +
                reformatStringForUrls(propertiesStore.addressParams.region) +
                (propertyTypeKey
                    ? `/${getI18nExpByLang(lang, "property.typesUrl." + propertyTypeKey).toLowerCase()}`
                    : "") +
                pageParams;
        } else {
            route =
                "/" +
                lang +
                "/" +
                getI18nExpByLang(lang, "routes.buy") +
                (propertyTypeLocalized
                    ? `/${getI18nExpByLang(lang, "property.typesUrl." + propertyTypeKey).toLowerCase()}`
                    : "") +
                pageParams;
        }

        alternateUrls.push({
            url: route,
            lang: lang as TLang,
        });
    }
    const url = isCityPage
        ? URLS[props.purpose === PROPERTY_PURPOSE.BUY ? "buy" : "rent"](
              i18next.language,
              reformatStringForUrls(propertiesStore.addressParams.region),
              reformatStringForUrls(propertiesStore.addressParams.city),
              propertiesStore.addressParams?.neighbourhood
                  ? reformatStringForUrls(propertiesStore.addressParams?.neighbourhood)
                  : propertyTypeLocalized
                  ? propertyTypeLocalized
                  : pageParams,
              propertiesStore.addressParams?.neighbourhood
                  ? propertyTypeLocalized
                      ? propertyTypeLocalized
                      : pageParams
                  : pageParams,
              propertiesStore.addressParams?.neighbourhood
                  ? propertyTypeLocalized
                      ? pageParams
                      : pageParams
                  : undefined,
          ).toLowerCase()
        : isProvincePage
        ? URLS[props.purpose === PROPERTY_PURPOSE.BUY ? "buy" : "rent"](
              i18next.language,
              reformatStringForUrls(propertiesStore.addressParams.region),
              propertyTypeLocalized ?? pageParams,
              propertyTypeLocalized ? pageParams : undefined,
          ).toLowerCase()
        : URLS[props.purpose === PROPERTY_PURPOSE.BUY ? "buy" : "rent"](
              i18next.language,
              propertyTypeLocalized ?? pageParams,
              propertyTypeLocalized ? pageParams : undefined,
          ).toLowerCase();
    return <Meta title={title} description={description} url={url} alternateUrls={alternateUrls} />;
}
